<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h3 class="text-primary">{{title}}</h3>
            <a :href="`${proxyUrl}admin/guide/file/${this.$route.params.guideId}/hy`" class="btn">
              <span class="text-primary mr-2">Կցված Ֆայլ</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download align-self-center menu-icon icon-dual"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
            </a>
          </div>
          <hr/>
          <div class="content" v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {  },
    name: 'guide',
    data() {
      return {
        proxyUrl: process.env.VUE_APP_URL,
        content: '',
        title: '',
      }
    },
    mounted() {
      this.$client.get(`guide/${this.$route.params.guideId}`).then(({data}) => {
        this.title = data.title.hy
        this.content = data.description.hy
      })
    },
    computed: {
      me() {
        return this.$store.getters['user/me']
      },
    },
    methods: {},
  }
</script>
<style>
.content img{
  max-width: 100%;
}
@media only screen and (max-width: 760px) {
  .content iframe{
    max-width: 100%;
    height: auto;
  }
}
</style>